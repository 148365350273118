import { RightOutlined, UpOutlined } from '@ant-design/icons'
import React, { useState } from 'react'

type CollapseProps = {
  target: JSX.Element
  children: JSX.Element
}

export const Collapse = ({ target, children }: CollapseProps) => {
  const [opened, setOpened] = useState<boolean>(false)

  return (
    <div className="flex flex-col grow py-5 border-t border-grey-800">
      <div
        className="flex grow items-center cursor-pointer text-white hover:text-green-300 gap-3"
        onClick={() => setOpened(prev => !prev)}
      >
        {opened ? <UpOutlined /> : <RightOutlined />}
        <div className="flex grow">{target}</div>
      </div>
      {opened && <div className="flex">{children}</div>}
    </div>
  )
}
