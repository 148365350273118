import { SubscriptionPlan } from '@r1-oas/public-subscription-plans'
import { SEARCH_PARAMS } from 'lib'
import React from 'react'
import { EXTERNAL_URLS } from '../constants'
import { Button } from './Button'
import { Typography } from './Typography'
import { ExternalButtonLink } from './ExternalButtonLink'

function pluralizeUsers(count: number) {
  return `${count} user${count !== 1 ? 's' : ''}`
}

export interface SubscriptionPlansProps {
  subscriptionPlans: SubscriptionPlan[]
}

export const SubscriptionPlans = ({ subscriptionPlans }: SubscriptionPlansProps) => {
  const hubspotLink = EXTERNAL_URLS.HUBSPOT_SCHEDULE_MEETING_LINK ?? ''
  const handleContactUs = () => {
    if (hubspotLink) {
      window.open(hubspotLink)
    }
  }
  return (
    <div className="flex flex-col">
      <div className="mb-8 flex flex-col">
        <div className="grid grid-cols-4 gap-5 max-lg:grid-cols-3 max-sm:grid-cols-2">
          {subscriptionPlans.map(plan => {
            const priceFixedPart = plan.price.toString(10).split('.')[0] ?? ''
            const priceDecimalPrice = plan.price.toString(10).split('.')[1] ?? '00'

            return (
              <div
                key={plan.id}
                className="min-h-28 mr-4 flex flex-col items-center rounded-md border border-solid border-[#d6dade] bg-white p-6"
              >
                <p className="text-muted text-center text-base font-medium leading-[1.1]">Up to</p>
                <Typography.H4 className="text-center text-primary-navy-dark">
                  {plan.returnsLimit}&nbsp;returns
                </Typography.H4>
                <p className="text-muted text-center text-base font-medium leading-[1.1]">
                  per month
                </p>
                <div className="mb-[0.625rem] mt-[1.25rem]">
                  <span className="text-[2.5rem] font-bold leading-10 text-primary-navy-dark">
                    ${priceFixedPart}
                    <sup className="-top-[1.5em] text-[0.875rem]">{priceDecimalPrice}*</sup>
                    <Typography.BodyS className="text-center font-normal text-nav-item-text">
                      per month
                    </Typography.BodyS>
                  </span>
                </div>
                <div className="mb-5 flex flex-col items-center text-center">
                  <Typography.BodyS className="text-muted mb-[0.375rem]">
                    {pluralizeUsers(plan.usersLimit)}
                  </Typography.BodyS>
                  <Typography.BodyXS className="text-muted">
                    ${plan.pricePerAdditionalUser} per additional user
                  </Typography.BodyXS>
                </div>
                <ExternalButtonLink
                  size="md"
                  variant="secondary"
                  href={`${EXTERNAL_URLS.SIGN_UP}?${SEARCH_PARAMS.SUBSCRIPTION_SELECTED_PLAN_ID}=${plan.id}`}
                >
                  Try for free
                </ExternalButtonLink>
              </div>
            )
          })}
        </div>

        <div className="flex justify-center">
          <div className="max-w-3xl">
            <Typography.BodyS className="mt-4 text-center text-grey-700">
              *If you exceed the number of returns within your selected tier, you’ll incur an
              overage charge based on the applicable returns volume tier. Supply Chain and
              ReCommerce services charged separately.
            </Typography.BodyS>
          </div>
        </div>
      </div>

      <div className="mb-10 flex w-full items-center justify-between rounded-xl border border-border-color bg-white p-6 max-sm:flex-col">
        <div className="flex flex-col gap-[0.625rem] max-sm:flex-row max-sm:items-center max-sm:justify-between">
          <Typography.H3>Custom pricing</Typography.H3>
          <Typography.BodyS className="text-nav-item-text">
            Have more than 1,200 returns per month? Contact us for a custom quote.
          </Typography.BodyS>
        </div>
        <Button size="md" variant="secondary" onClick={handleContactUs}>
          Contact us
        </Button>
      </div>
    </div>
  )
}
